<template>
    <v-row justify="center">
      <v-dialog
        v-model="open_dialog_token"
        scrollable
        persistent
        max-width="600px"
        v-if="resolutionScreen >= 500"
      >
        <v-card>
          <v-card-title>
            {{ $t("toolbar.onebox_token.header") }}
            <v-spacer></v-spacer>
            <v-icon :color="color.theme" dense dark size="35">mdi-key</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-flex xl12 class="pt-4">
              <v-text-field
                id="textfield"  
                class="mt-4"
                v-model="onebox_token_"
                outlined
                dense
                readonly
                prepend-icon="mdi-key"
                :disabled="isloading"
                :messages="isloading ? '' : used_time_token === 'Token Timeout' ? $t('toolbar.onebox_token.token_timeout') : $t('toolbar.onebox_token.token_time_using')"
              > 
              <template v-slot:message="{message}">
                <div class="d-flex flex-row" style="line-height: normal;">
                  <span style="font-size: 14px; color: red;"> {{message}} </span>
                  <v-spacer></v-spacer>
                  <span style="font-size: 14px; color: red;">
                  {{ used_time_token }}
                  </span>
                </div>
              </template>
              <template v-slot:append-outer >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon class="mt-n1" @click="fn_request_new_token()" :disabled="isloading">
                    <v-icon>
                      mdi-restart
                    </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('default') === 'en' ? 'Request new Token' : 'ขอTokenใหม่' }}
                </v-tooltip>
              </template>
            </v-text-field>
            </v-flex>
            <!-- <v-flex xl12 row >
              <v-spacer></v-spacer>
              <span>
                {{ used_time_token }}
              </span>
            </v-flex> -->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              dark
              @click="fn_closedialogToken()"
              >{{ $t("toolbar.onebox_token.close") }}</v-btn
            >
            <v-btn color="success" @click="fn_copy_token()" :disabled="used_time_token === 'Token Timeout' || isloading">{{
              $t("toolbar.onebox_token.copy")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="open_dialog_token"
        scrollable
        persistent
        max-width="600px"
        v-else
      >
        <v-card class="pa-0">
          <v-card-text>
            <v-layout class="pt-2 pb-1">
              <v-flex d-flex justify-center class="ml-8">
                <span :style="headerPage">{{
                  $t("toolbar.onebox_token.header")
                }}</span>
              </v-flex>
              <v-flex xs1 d-flex justify-end>
                <v-icon
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  @click="(open_dialog_token = false), $emit('closedialog')"
                  >mdi-close</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xl12 class="pt-4">
              <v-text-field
                id="textfield"
                class="mt-4"
                v-model="onebox_token_"
                outlined
                dense
                readonly
                rounded
                prepend-icon="mdi-key"
                :messages="used_time_token === 'Token Timeout' ? $t('toolbar.onebox_token.token_timeout') : `Tokenจะหมดอายุใน ${used_time_token}` "
              > 
              <template v-slot:message="{message}">
                <div class="d-flex flex-row py-1" >
                  <span style="font-size: 12px; color: red;"> {{message}} </span>
                  <v-spacer></v-spacer>
                  <span style="font-size: 12px; color: red;"> {{  $t('default') === 'en' ? 'Use only 1 time' : 'ใช้ได้เพียง 1 ครั้ง' }} </span>
                  <!-- <v-icon small @click="fn_request_new_token()" v-if="!isloading">
                    mdi-restart
                  </v-icon> -->
                  <!-- <span style="font-size: 12px;" v-else> {{used_time_token}} </span> -->
                </div>
              </template>
              <template v-slot:append-outer >
                <v-btn icon class="mt-n1" @click="fn_request_new_token()" v-if="!isloading" :disabled="isloading" >
                   <v-icon>
                    mdi-restart
                   </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            </v-flex>
          </v-layout>
          <div class="text-center">
              <v-btn
                :style="btnAction"
                class="mr-6"
                height="40px"
                width="45%"
                color="red"
                outlined
                @click="fn_closedialogToken()"
                >{{ $t("toolbar.onebox_token.close") }}</v-btn
              >
              <v-btn
                class="elevation-0"
                :style="btnAction"
                height="40px"
                width="45%"
                :color="color.theme"
                @click="fn_copy_token()"
                :dark="isloading ? false : used_time_token === 'Token Timeout' ? false : true"
                :disabled="used_time_token === 'Token Timeout' || isloading"
                >{{ $t("toolbar.onebox_token.copy") }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import axios from "axios";
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
    props: ["isopen_token"],
    data: () => ({
      open_dialog_token: false,
      isloading: false,
      onebox_token_: "",
      used_time_token: "",
      countdown_interval: null
    }),
    computed: {
      ...mapState(["username", "authorize", "account_active", "color"]),
      ...mapGetters(["dataCitizenProfile", "dataBusinessProfile"]),
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      headerPage() {
        return (
          "color:" +
          this.color.theme +
          ";" +
          "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
        );
      },
      btnAction() {
        return "font-size: 16px; font-weight: lighter;";
      },
    },
    watch: {
      isopen_token: function (val) {
        if (val === true) {
          this.open_dialog_token = true;
          this.isloading = true;
          this.$emit('closeDragMain')
          this.fn_fetch_token();
        } else {
          console.log("Open Dialoggg ")
          this.open_dialog_token = false;
          this.isloading = false;
          this.$emit('closedialog')
          this.$emit('openDragMain')
        }
      },
    },
    methods: {
      fn_closedialogToken () {
        this.open_dialog_token = false
        setTimeout(() => {
          this.used_time_token = ""
          this.onebox_token_ = ""
          this.$emit('closedialog')
          clearInterval(this.countdown_interval)
        }, 200)
        
      },
      fn_copy_token () {
        try {
            let input = document.getElementById("textfield");
            input.select();
            document.execCommand("copy");
            Toast.fire({
                icon: "success",
                title: this.$t('default') === 'en' ? "Copied Token" : "คัดลอกTokenสำเร็จ",
            }); 
        } catch (err) {
            Toast.fire({
                icon: "error",
                title: this.$t('default') === 'en' ? "Try again" : "ลองใหม่อีกครั้ง",
            }); 
        }
      },
      async fn_fetch_token() {
        this.used_time_token = ""
        let payload = {
        user_id: this.dataCitizenProfile.user_id,
        service_name: "onebox",
        biz: this.dataBusinessProfile,
        user_info: this.dataCitizenProfile
        }
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios.post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/get_token/login",
          payload,
          {
            headers: { Authorization: auth.code }
          }
        )
        console.log("resullt tokem ", result)
        if (result.data.status === 'OK') {
          this.onebox_token_ = result.data.token_login
          this.used_time_token = result.data.time_out.split(".")[0]
          let parseTime = this.parseTimeString(this.used_time_token.split(".")[0])
          this.startCountdown(parseTime)
          this.isloading = false
        } else {
          Toast.fire({
              icon: "error",
              title: "บางอย่างผิดหลาดลองใหม่อีกครั้ง",
          });
        }
      },
      parseTimeString(timeString) {
        const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
        return (hours * 3600 + minutes * 60 + seconds);
      },
      formatTime(seconds) {
        const hours = String(Math.floor(seconds / 3600)).padStart(1, '0');
        const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
        const remainingSeconds = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${remainingSeconds}`;
      },
      startCountdown(duration) {
        let remainingTime = duration;
  
        this.countdown_interval = setInterval(() => {
          remainingTime -= 1;
          if (remainingTime < 0) {
            clearInterval(this.countdown_interval);
            this.used_time_token = 'Token Timeout';
          } else {
            this.used_time_token = this.formatTime(remainingTime);
          }
        }, 1000);
      },
      fn_request_new_token () {
        this.isloading = true
        this.used_time_token = ""
        clearInterval(this.countdown_interval)
        this.fn_fetch_token()
      },
      
    },
    mounted() {},
  };
  </script>
  
  <style>
  </style>